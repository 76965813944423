<template>
  <div class="indicador-view-contenedor">
      <div class="ficha-indicador">
          <Nav/>
          <Titulo
            :indicador="indicador"
            :periodo="periodo"
        />
        <NoPage/>
          
      </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
    components: {
        Nav: defineAsyncComponent( () => import('../../components/Nav.vue')),
        Titulo: defineAsyncComponent( () => import('../../components/ficha-titulo.vue')),
        NoPage: defineAsyncComponent( () => import('../../components/no-informacion.vue'))
    },
     data() {
        return {
            indicador: 'Comparecencias',
            periodo: '2021'
        }
    }
}
</script>

<style lang="scss" scoped>

    .indicador-view-contenedor{
        width:70%;
        margin:0 auto;
        height:calc(100vh - 60px);
        background-color: #fff;
        display:flex; align-items:center; justify-content:center;

        .ficha-indicador{
            width:94%;
            margin:10px auto;
            height:calc(100vh - 120px);
            background-color: #f2f2f2;
        }
    }

</style>